class SliderAutoplayWidgetHandler extends elementorModules.frontend.handlers
    .Base {
    updateSlider() {
        const currentEle = this.$element[0]
        const sliderEle = currentEle.querySelector('.slider-autoplay__wrapper')

        if (currentEle && sliderEle) {
            new Swiper(sliderEle, {
                slidesPerView: 3,
                speed: 10000,
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    992: {
                        slidesPerView: 3,
                    },
                },
                initialSlide: 1,
                autoplay: {
                    delay: 0,
                },
                loop: true,
                allowTouchMove: false,
                disableOnInteraction: true,
            })
        }
    }

    onInit() {
        this.updateSlider()
    }
}

window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.elementsHandler.attachHandler(
        'hiqua_slider_autoplay',
        SliderAutoplayWidgetHandler
    )
})
